
import { Options, Vue } from "vue-class-component";
import { Document } from "@/types/NewDocument";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import { IDepartment } from "@/types/Department";
import Breadcrumb from "@/components/Breadcrumb.vue";
import * as yup from "yup";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import DropdownComponent from "@/components/DropdownComponent.vue";
import { notify } from "@/services/helpers";

@Options({
  name: "NewDocument",
  components: {
    Breadcrumb,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
    Dropdown: DropdownComponent,
  },
  data: function () {
    return {
      schema: yup.object().shape({
        name: yup
          .string()
          .required("Please provide a name.")
          .max(75, "Must not exceed 75 characters in length."),
        version: yup
          .string()
          .required("Please provide a version.")
          .max(10, "Must not exceed 10 characters in length."),
        file: yup.mixed().required("Please select a file."),
      }),
    };
  },
  props: {},
})
export default class NewDocument extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  departments: IDepartment[] = [];
  fields: any = { text: "name", value: "id" };
  newDocument: Document = {
    id: 0,
    name: "",
    version: "",
    addedBy: 0,
    departmentAssigned: 0,
    createdOn: "",
    isArchived: false,
    file: "",
  };
  showDept = false;
  crumbs: any = ["Dashboard", "Manage Documents", "New Document"];
  loading = false;

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  message = "";
  protected file: any;
  async save() {
    this.message = "";
    this.file = this.$refs.uploadDocument;
    this.newDocument.file = this.file.files[0];

    const formData = new FormData();
    formData.append("file", this.newDocument.file);
    formData.append("name", this.newDocument.name);
    formData.append("version", this.newDocument.version);
    formData.append(
      "departmentAssigned",
      this.newDocument.departmentAssigned.toString()
    );
    this.loading = true;

    await this.workForceService
      .postMulitPart<void>("/documents", formData as any, true)
      .then((response: any) => {
        this.loading = false;
        if (!response.isError) {
          notify("Document added successfully.", "Success", "success");
          this.goToRoute(`/admin/documents/manage`);
        } else {
          this.message = response.errors;
          notify(response.errors, "Error", "danger");
        }
      });
  }

  async initialize() {
    await this.workForceService
      .get<IDepartment[]>(`/departments?PageIndex=0&PageSize=100`, false)
      .then((response: any) => {
        this.departments = response.content.items;
      });
  }

  async created() {
    await this.initialize();
  }

  picked = "";
  rdChange() {
    if (this.picked == "Department") {
      this.showDept = true;
    } else {
      this.showDept = false;
    }
  }
}
